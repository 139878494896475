module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":"46","icon":"<svg aria-hidden=\"true\" width=\"12\" height=\"15\" viewBox=\"0 0 12 15\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M0 15V1.40625C0 0.62959 0.62959 0 1.40625 0H9.84375C10.6204 0 11.25 0.62959 11.25 1.40625V15L5.625 11.7188L0 15Z\" fill=\"#DBDBDB\"/></svg>"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-65766767-1","anonymize":true,"respectDNT":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
